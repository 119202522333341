<template>
    <div class="goods-item">
        
        <div class="kou-box" v-if="schemeModel == 'professional'" @click.stop="selectKou">
            <span>抠</span>
        </div>

        <div class="col-btn" @click.stop="collect()" :class="{ 'collected': goods.is_collect }">
            <template v-if="goods.is_collect">
                <img src="/assets/images/deck/collected.png">
            </template>
            <template v-else>
                <img src="/assets/images/deck/collect-none.png">
            </template>
        </div>
        
        <div class="image-container" :style="`background-image: url(/assets/images/deck/msk.png)`">
            <img class="thumb" :src="goods.white_image || goods.thumb || goods.thumb_image">
            <div class="select-thumb" v-if="schemeModel == 'simple' && (goods.from == 'youzan' || goods.from == 'wechat' || goods.from == 'yc-shop')" @click.stop="selectThumb">
                <span>更换图片</span>
                <img src="/assets/images/deck/deck-select-thumb-icon.png">
            </div>
        </div>
        
        <div class="goods-detail">
            <div class="goods-name">
                <template v-if="!hiddenPlate ">
                    <div class="goods-plate">
                        <span v-if="goods.from === 'jd' " class="iconfont pc-jingdong"></span>
                        <span v-else-if="goods.from === 'tb' " class="iconfont pc-taobao"></span>
                        <span v-else-if="goods.from === 'vip' " class="iconfont pc-weipinhui"></span>
                        <span v-else-if="goods.from === 'yc-shop' " class="pc-yc"></span>
                        <span v-else-if="goods.from === 'youzan' " class="pc-youzan"></span>
                        <span v-else class="pc-wechat"></span>
                    </div>
                </template>
                
                <div class="name">{{ goods.sku_name || goods.title || goods.product_name }}</div>
            </div>
            <div class="goods-prices">
                <div class="price">
                    <span class="lowest">
                        <span class="fh">￥</span>
                        <template v-if=" goods.from === 'wechat' ">
                            {{ (goods.min_price / 100.0).toFixed(2) }}
                        </template>
                        <template v-else>
                            {{ goods.price }}
                        </template>
                    </span>
                </div>
                <div class="comm"
                     v-if="!hiddenComm && (goods.from !== 'wechat') && goods.commission &&  goods.commission > 0 ">
                    <span class="dz">搭赚</span> <span class="fh">￥</span>
                    <span class="comm-am">{{ goods.commission }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import api from "../repo/api";

export default {
    name: "GoodsItem",
    props: {
        goods: Object,
        hiddenPlate: Boolean,
        schemeModel: String,
        hiddenComm: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            bGoodsFrom: [
                'jd', 'tb', 'vip'
            ]
        }
    },
    methods: {
        selectKou() {
            this.$emit('selectKou', this.goods)
        },
        selectThumb() {
            this.$emit('selectThumb', this.goods)
        },
        collect() {
            
            if (this.goods.collecting) return
            
            this.goods.collecting = true
            
            let attr = {
                ast_goods_id: null,
                b_goods_id: null,
                collect: this.goods.is_collect ? 0 : 1,
                color: "",
            }
            
            
            if (this.bGoodsFrom.indexOf(this.goods.from) >= 0) {
                attr.b_goods_id = this.goods.id
            } else {
                
                if (typeof this.goods.id === 'string' && this.goods.id.indexOf('-') >= 0) {
                    
                    const [goodsId, color] = this.goods.id.split('-')
                    
                    attr.ast_goods_id = goodsId
                    attr.color = color
                    
                } else {
                    
                    attr.ast_goods_id = this.goods.id
                    
                }
            }
            
            
            api.post('/ast-shop/goods-collect', attr).then(data => {
                
                this.goods.collecting = false
                
                if (data.code === 0) {
                    this.goods.is_collect = !this.goods.is_collect
                }
                
            })
            
        },
    }
}
</script>

<style lang="less" scoped>
.goods-item {
    height: 265px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    position: relative;
}

.goods-name {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    
    .name {
        flex: 1;
        line-height: 18px;
        overflow: hidden;
        display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
        -webkit-line-clamp: 2; /* 行数，值可以改，表示展示X行后多余的缩略展示 */
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        word-break: break-all;
    }
}

.goods-plate {
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: #dddddd;
    margin-right: 4px;
}

.image-container {
    width: 200px;
    height: 200px;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    
    
    background-color: #F5F5F5;
    margin-bottom: 8px;

    position: relative;
    .thumb{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .select-thumb {
        height: 21px;
        background: rgba(0,0,0,0.4);
        border-radius: 23px;
        position: absolute;
        bottom: 8px;
        right: 8px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        span{
            color: #fff;
            font-size: 12px;
            display: inline-block;
        }
        img{
            width: 12px;
            height: 12px;
            margin-left: 4px; 
        }
    }
}

.goods-detail {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}

.goods-prices {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.fh {
    font-size: 12px;
}

.lowest {
    color: rgba(227, 29, 26, 1);
    display: inline-block;
    margin-right: 9px;
    
    .fh {
        color: rgba(227, 29, 26, 1);
    }
}

.origin {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
}

.comm {
    .fh {
        color: rgba(255, 173, 0, 1);
    }
}

.comm-am {
    color: rgba(255, 173, 0, 1);
}

.dz {
    color: #999;
}

.col-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    border-radius: 100%;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    
    span {
        font-size: 14px;
    }
    
    &.collected {
        opacity: 1;
    }

    img{
        width: 24px;
        height: 24px;
    }
}

.kou-box{
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 100%;
    background: rgba(255,255,255,0.8);
    color: #333;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    left: 12px;
    top: 164px;
    z-index: 99;
    display: none;
    box-shadow: 3px 3px 8px 4px rgba(0, 0, 0, 0.22);
}

.goods-item:hover .col-btn {
    opacity: 1;
}

.goods-item:hover .kou-box {
    display: block;
}

</style>